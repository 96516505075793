import * as React from 'react'
import { useEffect, useState, useLayoutEffect } from 'react'
import Layout from '../components/Layout/Layout'

function htmlDecode(html) {
  return html.replace(/&([a-z]+);/gi, (match, entity) => {
    const entities = {
      amp: '&',
      apos: "'",
      gt: '>',
      lt: '<',
      nbsp: '\xa0',
      quot: '"'
    }
    entity = entity.toLowerCase()
    if (entities.hasOwnProperty(entity)) {
      return entities[entity]
    }
    return match
  })
}
const makeCode = (gtin, list = '') => {
  return `<div class="consu-widget" data-gtin='${gtin}'${
    list !== '' ? ` list="${list}"` : ''
  }></div><script>var s=document.createElement('script');s.setAttribute('src','https://static.consupedia.com/index.js?v=2.0.12');s.onload=function(){window.consupedia()};document.body.appendChild(s);</script><link rel="stylesheet" href="https://static.consupedia.com/index.css?v=2.0.12">`
}
const defaultCode = '7350126860167'
// markup
const Stackys = () => {
  const scriptCode = `<link rel="stylesheet" href="https://static.consupedia.com/index.css?v=2.0.12">`
  const [demotin, setDemotin] = useState(defaultCode)
  const [code, setCode] = useState('')
  const [climate, setClimate] = useState(false)
  const [fairness, setFairness] = useState(false)
  const [health, setHealth] = useState(false)
  const [transport, setTransport] = useState(false)
  const [water, setWater] = useState(false)
  const [socialJustice, setSocialJustice] = useState(false)
  const [workerRights, setWorkerRights] = useState(false)
  const [equality, setEquality] = useState(false)
  const [childLabor, setChildLabor] = useState(false)
  const [fertilizersUse, setFertilizersUse] = useState(false)
  const [pesticide, setPesticide] = useState(false)
  const [biodiversity, setBiodiversity] = useState(false)

  useLayoutEffect(() => {
    var s = document.createElement('script')
    s.setAttribute('src', 'https://static.consupedia.com/index.js?v=2.0.12')
    s.onload = () => window.consupedia()
    document.body.appendChild(s)
    setCode(makeCode(defaultCode))
  }, [])

  useEffect(() => {
    setCode(makeCode(demotin, list))
    window.consupedia && window.consupedia()
  }, [
    demotin,
    climate,
    fairness,
    health,
    water,
    transport,
    socialJustice,
    workerRights,
    equality,
    childLabor,
    fertilizersUse,
    pesticide,
    biodiversity
  ])

  const list = [
    climate ? 'climate' : '-',
    fairness ? 'fairness' : '-',
    health ? 'health' : '-',
    water ? 'water' : '-',
    transport ? 'transport' : '-',
    socialJustice ? 'socialJustice' : '-',
    workerRights ? 'workerRights' : '-',
    equality ? 'equality' : '-',
    childLabor ? 'childLabor' : '-',
    fertilizersUse ? 'fertilizersUse' : '-',
    pesticide ? 'pesticide' : '-',
    biodiversity ? 'biodiversity' : '-'
  ]
    .filter((a) => a !== '-')
    .join(',')

  return (
    <Layout>
      <title>Consupedia widget</title>
      <div className="hero dark-bg alignfull">
        <div className="content-container">
          <div>
            <h1>Stackys</h1>
            <h2>Widget | 7350126860167</h2>
            <br />
            {/* <div className="consu-widget" data-gtin="7314660002155"></div> */}
            {/* <h4>
              Vi har världens största databas för hållbarhetsinformation om
              livsmedel.
            </h4> */}
          </div>
        </div>
      </div>
      <section className="alignfull">
        <div className="section-container">
          <div>
            <h2>Visa Consupedias hållbarhetsdata på din webbsida</h2>
            <p>
              Vi på Consupedia har byggt världens största databas för
              hållbarhetsinformation om livsmedel tillsammans med KTH och
              Högskolan Dalarna. Nu kan du använda den för att informera dina
              kunder och besökare om dina produkters hållbarhet.
            </p>
            <p>
              <strong>Var med och gör världen bättre, du också.</strong>
            </p>
          </div>
        </div>
      </section>
      <section className="alignfull green-bg">
        <div className="section-container">
          <div className="single">
            <h2>Skapa din widget</h2>
          </div>
          <div className="cols">
            <div className="col">
              <div className="demo-form">
                <div style={{ display: 'none' }}>
                  <strong>Skriv in din Ean-kod: </strong>
                  <input
                    type="text"
                    defaultValue={defaultCode}
                    onChange={(e) =>
                      e.target.value > 1000 ? setDemotin(e.target.value) : null
                    }
                  />
                </div>
                <strong>Visa enskilt: &nbsp;</strong>
                <ul className="consupedia-list">
                  <li>
                    <input
                      type="checkbox"
                      checked={climate}
                      onChange={() => setClimate(!climate)}
                    ></input>{' '}
                    Klimat
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={health}
                      onChange={() => setHealth(!health)}
                    ></input>{' '}
                    Hälsa
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={water}
                      onChange={() => setWater(!water)}
                    ></input>{' '}
                    Vatten
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={transport}
                      onChange={() => setTransport(!transport)}
                    ></input>{' '}
                    Transport
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={socialJustice}
                      onChange={() => setSocialJustice(!socialJustice)}
                    ></input>{' '}
                    Social Rättvisa
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={workerRights}
                      onChange={() => setWorkerRights(!workerRights)}
                    ></input>{' '}
                    Arbetsförhållande
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={equality}
                      onChange={() => setEquality(!equality)}
                    ></input>{' '}
                    Jämlikhet
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={childLabor}
                      onChange={() => setChildLabor(!childLabor)}
                    ></input>{' '}
                    Barnarbete
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={fertilizersUse}
                      onChange={() => setFertilizersUse(!fertilizersUse)}
                    ></input>{' '}
                    Övergödning
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={pesticide}
                      onChange={() => setPesticide(!pesticide)}
                    ></input>{' '}
                    Bekämpningsmedel
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      checked={biodiversity}
                      onChange={() => setBiodiversity(!biodiversity)}
                    ></input>{' '}
                    Biologisk mångfald
                  </li>
                </ul>
                <div>
                  <br />
                  <p>
                    Kopiera och klistra in nedan kod där du vill att din widget
                    ska vara placerad:
                  </p>
                  <textarea value={code} readOnly></textarea>
                  <br />
                  <br />
                  <br />
                </div>
              </div>
              <div className="demo-form">
                <div>
                  <strong>Använd flera widgetar på samma sida</strong>
                  <p>
                    Lägg till flera widgetar på en sida genom att placera nedan
                    kod innan {`</body>`} -taggen:
                  </p>
                  <textarea
                    value={`<script>var s = document.createElement('script');s.setAttribute('src','https://static.consupedia.com/index.js?v=2.0.12');s.onload = function(){window.consupedia()};document.body.appendChild(s);</script><link rel="stylesheet" href="https://static.consupedia.com/index.css?v=2.0.12">`}
                    readOnly
                  ></textarea>
                  <p>
                    Lägg sedan in nedan tagg på sidan där du vill att en widget
                    ska visas:
                  </p>
                  <code>{`<div class="consu-widget" data-gtin='{DIN-EAN-KOD}'></div>`}</code>
                  <p>
                    För att endast visa valda värden, lägg till nedan
                    data-attribut:
                  </p>
                  <code>{`<div class="consu-widget" data-gtin='{DIN-EAN-KOD}' data-list='{värdelista}'></div>`}</code>
                  <p>Exempel:</p>
                  <code>{`<div class="consu-widget" data-gtin='7350126860167' data-list='climate,health'></div>`}</code>
                </div>
              </div>
            </div>
            <div className="col">
              <div
                className="consu-widget"
                data-gtin={demotin}
                data-list={list}
              ></div>
              <div dangerouslySetInnerHTML={{ __html: scriptCode }}></div>
            </div>
          </div>
        </div>
      </section>
      <section className="alignfull">
        <div className="section-container">
          <div>
            <h2>Support</h2>
            <p>
              Behöver du hjälp att implementera widgeten på din webbsida?
              Kontakta oss:
            </p>
            <p>
              <strong>
                <a href="mailto:info@consupedia.com">info@consupedia.com</a>
              </strong>
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Stackys
